import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import NewCardSetup from "./NewCardSetup";
import {
	CardNumberElement,
	useElements,
	useStripe,
} from "@stripe/react-stripe-js";
import LoadingElement from "../LoadingElement/LoadingElement";
import PaymentFormDataType from "../../Types/PaymentFormDataType";
import PaymentService from "../../services/payment.service";

type Props = {
	exitCallback: Function;
};
function PaymentMethodForm(props: Props) {
	const STREETADDRESSLINETWOBILLINGSTRING = "streetAddressLineTwoBilling";
	const stripe = useStripe();
	const elements = useElements();
	const [step, setStep] = useState(0);
	const [loading, setLoading] = useState(true);
	const [formData, setformData] = useState<PaymentFormDataType>({
		firstNameBilling: "",
		lastNameBilling: "",
		streetAddressLineOneBilling: "",
		streetAddressLineTwoBilling: "",
		cityBilling: "",
		stateBilling: "",
		zipcodeBilling: "",
	});
	const [cardError, setCardError] = useState("");
	const [setupIntent, setSetupIntent] = useState<any>(undefined);
	const [submitHandler, setSubmitHandler] = useState(false);

	useEffect(() => {
		setLoading(true);
		createSetupIntent();
		setLoading(false);
	}, []);

	async function createSetupIntent() {
		const result = await PaymentService.getSetupIntent();
		setSetupIntent(result);
		console.log(result);
	}

	function stepHandler(nextStep: number) {
		setStep(nextStep);
	}

	function onChangeHandler(event: ChangeEvent<HTMLInputElement>) {
		const newData = {
			...formData,
			[event.target.name]: event.target.value,
		};
		setformData(newData);

		// Check if inputs are filled out
		const asArray = Object.entries(newData);
		const filteredResult = asArray.filter(
			([key, value]) =>
				key !== STREETADDRESSLINETWOBILLINGSTRING && value.length > 0
		);
		if (filteredResult.length === Object.keys(newData).length - 1)
			setSubmitHandler(true);
		else setSubmitHandler(false);
	}

	async function submit(e: FormEvent<HTMLFormElement>) {
		e.preventDefault();
		setLoading(true);

		if (!stripe || !elements) {
			// Stripe.js hasn't yet loaded.
			// Make sure to disable form submission until Stripe.js has loaded.
			return null;
		}
		const { error } = await stripe.confirmCardSetup(setupIntent.client_secret, {
			payment_method: {
				card: elements.getElement(CardNumberElement)!,
				billing_details: {
					address: {
						city: formData.cityBilling,
						line1: formData.streetAddressLineOneBilling,
						line2: formData.streetAddressLineTwoBilling,
						postal_code: formData.zipcodeBilling,
						state: formData.stateBilling,
					},
					name: `${formData.firstNameBilling} ${formData.lastNameBilling}`,
				},
			},
		});
		if (error) {
			setLoading(false);
			setCardError(error.message ?? "Something went wrong. Try again.");
		} else {
			props.exitCallback();
		}
	}

	return (
		<form id="newCardForm" onSubmit={submit}>
			{loading && <LoadingElement size={2} />}
			<NewCardSetup
				step={step}
				setStep={stepHandler}
				formData={formData}
				setFormDataHandler={setformData}
				setCardError={setCardError}
			/>
			{step === 1 && !loading && (
				<div id="billingInputs">
					<ul id="nameInputs" className="formGroup">
						<li className="shortInput">
							<label htmlFor="firstNameBilling">First Name</label>
							<input
								onChange={onChangeHandler}
								type="text"
								name="firstNameBilling"
								id="firstNameBilling"
								placeholder="Johnny"
								value={formData.firstNameBilling}
							/>
						</li>
						<li className="shortInput">
							<label htmlFor="lastNameBilling">Last Name</label>
							<input
								onChange={onChangeHandler}
								type="text"
								name="lastNameBilling"
								id="lastNameBilling"
								placeholder="Appleseed"
								value={formData.lastNameBilling}
							/>
						</li>
						<li className="shortInput">
							<label htmlFor="streetAddressLineOneBilling">
								Street Address Line 1
							</label>
							<input
								onChange={onChangeHandler}
								type="text"
								name="streetAddressLineOneBilling"
								id="streetAddressLineOneBilling"
								placeholder="1234 Example St."
								value={formData.streetAddressLineOneBilling}
							/>
						</li>
						<li className="shortInput">
							<label htmlFor="streetAddressLineTwoBilling">
								Street Address Line 2
							</label>
							<input
								onChange={onChangeHandler}
								type="text"
								name="streetAddressLineTwoBilling"
								id="streetAddressLineTwoBilling"
								placeholder="Apt 1234"
								value={formData.streetAddressLineTwoBilling}
							/>
						</li>
						<li className="shortInput">
							<label htmlFor="cityBilling">City</label>
							<input
								onChange={onChangeHandler}
								type="text"
								name="cityBilling"
								id="cityBilling"
								placeholder="Austin"
								value={formData.cityBilling}
							/>
						</li>
						<li className="shortInput">
							<label htmlFor="stateBilling">State</label>
							<input
								onChange={onChangeHandler}
								type="text"
								name="stateBilling"
								id="stateBilling"
								placeholder="Texas"
								value={formData.stateBilling}
							/>
						</li>
						<li className="shortInput">
							<label htmlFor="zipcodeBilling">Zipcode</label>
							<input
								onChange={onChangeHandler}
								type="text"
								name="zipcodeBilling"
								id="zipcodeBilling"
								placeholder="12345"
								value={formData.zipcodeBilling}
							/>
						</li>
					</ul>
					<div className="nextButtonDiv">
						<button
							type="submit"
							id="dateTimeNext"
							className={
								submitHandler ? "nextButton" : "nextButton deactivated"
							}
							disabled={!submitHandler}
						>
							Add payment method
						</button>
					</div>
				</div>
			)}
			<p className="cardError">{cardError}</p>
		</form>
	);
}

export default PaymentMethodForm;
